<div id="TipQuestionnaireAnswersBox" class="card card-default" [attr.aria-expanded]="collapsed">
    <div class="card-header d-flex justify-content-between" (click)="toggleCollapse(); utilsService.stopPropagation($event)">
        <span>{{'Questionnaire answers'|translate}}</span>
        <span class="">
            <i *ngIf="!collapsed" class="fa-solid fa-chevron-up" data-toggle="tooltip" title="{{'Collapse' | translate}}"></i>
            <i *ngIf="collapsed" class="fa-solid fa-chevron-down" data-toggle="tooltip" title="{{'Expand' | translate}}"></i>
        </span>
    </div>
    <div class="card-body" *ngIf="!collapsed">
        <div *ngFor="let questionnaire of tipService.tip.questionnaires;let index = index">
            <div *ngIf="tipService.tip.questionnaires.length > 1">
                <span>{{'Questionnaire'|translate}}</span>
                <span>{{index + 1}}</span>
            </div>
            <div *ngFor="let step of questionnaire.steps | orderBy:'order'; let questionare_steps = index">
                <dt id="step={{questionare_steps}}" *ngIf="questionnaire.steps.length > 1 && tipService.tip.context.show_steps_navigation_interface && step.enabled">
                    <span>{{'Step'|translate}}</span>
                    <span>{{questionare_steps + 1}}: {{step.label}}</span>
                </dt>
                <div *ngIf="step.enabled">
                    <div *ngFor="let field of step.children | orderBy:['y', 'x']; let index_step = index">
                        <ng-container>
                            <src-tip-field [fieldAnswers]="questionnaire.answers" [redactOperationTitle]="redactOperationTitle" [redactMode]="redactMode" [fields]="field" [index]="index_step"></src-tip-field>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>