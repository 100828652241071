<div id="TipInfoBox" class="card card-default">
    <div class="card-header clearfix" [attr.data-cy]="'identity_toggle'" (click)="toggleCollapse(); utilsService.stopPropagation($event)">
        <span>{{'Identity' | translate}}</span>
        <span class="float-end">
            <i *ngIf="!collapsed" class="fa-solid fa-chevron-up" data-toggle="tooltip" title="{{'Collapse' | translate}}"></i>
            <i *ngIf="collapsed" class="fa-solid fa-chevron-down" data-toggle="tooltip" title="{{'Expand' | translate}}"></i>
        </span>
    </div>
    <div class="card-body" *ngIf="!collapsed">
        <div *ngIf="tipService.tip.iar && tipService.tip.iar.reply !== 'authorized'">
            <div>
                <label>{{"Access to the whistleblower's identity has been requested to the custodian." | translate}}</label>
            </div>
            <div>
                <label>{{'Date of the request' | translate}}</label>
                <span>:</span>
                <span>{{tipService.tip.iar.request_date | date:'dd-MM-yyyy HH:mm'}}</span>
            </div>
            <div>
                <label>{{'Request status' | translate}}</label>
                <span>:</span>
                <span [ngSwitch]="tipService.tip.iar.reply">
                    <span *ngSwitchCase="'pending'">{{ 'Waiting for authorization' | translate }}</span>
                    <span *ngSwitchCase="'denied'">{{ 'Denied' | translate }}</span>
                </span>
                <div><label>{{'Motivation' | translate}}</label>:<span> {{tipService.tip.iar.request_motivation}}</span></div>
            </div>
            <div *ngIf="tipService.tip.iar && tipService.tip.iar.reply === 'denied'">
                <label>{{'Reply motivation' | translate}}</label>: <span>{{tipService.tip.iar.reply_motivation}}</span>
            </div>
        </div>
        <span *ngIf="!tipService.tip?.data?.whistleblower_identity_provided">-</span>
        <div *ngIf="tipService.tip.data.whistleblower_identity_provided">
            <div *ngIf="tipService.tip.iar && tipService.tip.iar.reply === 'authorized'">
                <div *ngFor="let field of tipService.tip.whistleblower_identity_field.children ; let index = index">
                    <src-tip-field [fields]=field [fieldAnswers]=tipService.tip.data.whistleblower_identity [redactOperationTitle]="redactOperationTitle" [redactMode]="redactMode" [index]="index"></src-tip-field>
                </div>
            </div>
            <div *ngIf="!tipService.tip.iar || tipService.tip.iar.reply === 'denied'">
                <button *ngIf="tipService.tip.custodian" class="btn btn-sm btn-primary" id="identity_access_request" (click)="fileIdentityAccessRequest()">{{"Request access to the whistleblower's identity" | translate}}</button>
                <button *ngIf="!tipService.tip.custodian" class="btn btn-sm btn-primary" (click)="accessIdentity()">{{'Show' | translate}}</button>
            </div>
            <div *ngIf="tipService.tip.data.whistleblower_identity_date !== tipService.tip.creation_date" class="mt-3" [ngClass]="{'nested': tipService.tip.iar && tipService.tip.iar.reply === 'authorized'}">
                <span>{{ 'Subscription date'|translate }}</span> :  <span>{{tipService.tip.data.whistleblower_identity_date | date:'dd-MM-yyyy HH:mm'}}</span>
            </div>
        </div>
    </div>
</div>