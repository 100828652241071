<div id="AttachedWBFile" class="card card-default" *ngIf="wbTipService.tip.rfiles.length>0">
    <div class="card-header clearfix" (click)="toggleCollapse(); utilsService.stopPropagation($event);">
        <span>{{'Files attached by recipients'|translate}}</span>
        <span class="float-end">
            <i *ngIf="!collapsed" class="fa-solid fa-chevron-up" data-toggle="tooltip" title="{{'Collapse' | translate}}"></i>
            <i *ngIf="collapsed" class="fa-solid fa-chevron-down" data-toggle="tooltip" title="{{'Expand' | translate}}"></i>
        </span>
    </div>
    <div class="card-body" *ngIf="!collapsed">
        <div *ngFor="let wbfile of wbTipService.tip.rfiles | orderBy: 'creation_date'">
            <div id="rfile-{{index}}">
                <src-wbfiles [ctx]="ctx" (dataToParent)="listenToWbfiles($event)" [receivers_by_id]="receivers_by_id" [wbFile]="wbfile"></src-wbfiles>
            </div>
        </div>
    </div>
</div>