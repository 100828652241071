<div class="modal-header">
    <div class="modal-title">{{ 'Request access to the whistleblower\'s identity' | translate }}</div>
    <button type="button" class="close btn" (click)="cancel()" [attr.aria-label]="'Close' | translate">
        <i class="fa-solid fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <label>{{ 'Motivation' | translate }}</label>
    <span class="text-danger" data-toggle="tooltip" [title]="'This field is mandatory' | translate">*</span>
    <textarea class="form-control" name="request_motivation" [(ngModel)]="request_motivation" [placeholder]="'Motivation' | translate"></textarea>
</div>
<div class="modal-footer">
    <button id="modal-action-ok" class="btn btn-primary" [disabled]="!request_motivation" (click)="confirm()">
        <i class="fa-solid fa-check"></i>
        <span>{{ 'Send' | translate }}</span>
    </button>
    <span id="modal-action-cancel" class="btn btn-outline-secondary" (click)="cancel()">
        <i class="fa-solid fa-times"></i>
        <span>{{ 'Cancel' | translate }}</span>
    </span>
</div>